import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { headers } from './data.service';

@Injectable({
    providedIn: 'root'
})

export class CallDataForm {
    constructor(private http: HttpClient) {}
    fetchData(ep: any): Observable<any> {
        return this.http.get(ep, {headers});
    }
}

