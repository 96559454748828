import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../_services';
import { messages } from '../_helpers/message.dialog';
import { language } from '../../locale/translation';
import {MessageService} from '../_services/message.service';
import {hasParams, searchParams} from '../_helpers/functions';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  language = language;
  messages = messages;
  param: Subscription;
  regExpNumber = (/^[0-9]*$/);
  isValidParam: any = (
      hasParams() &&
      ( searchParams(this.router.url)[0] !== ''                   &&
          searchParams(this.router.url)[0] !== undefined            &&
          this.regExpNumber.test(searchParams(this.router.url)[0] )
      )
  );

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    public messageService: MessageService
  ) { }

  ngOnInit() {
    this.param = this.route.queryParams.subscribe(params => {
      this.messageService.paramValue.next(Object.values(params)[0]);
    });

    this.loginForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  // for accessing to form fields
  get fval() { return this.loginForm.controls; }

  onFormSubmit() {
    this.submitted = true;
    // Check if has params and it is valid
    if (!this.isValidParam) {
      this.toastr.error(
          this.messages[this.messageService.getLanguage].home.parameterNotValid,
          this.messages[this.messageService.getLanguage].home.title
      );
      return;
    }

    // Check if form is valid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.login(this.fval.user.value.trim(), this.fval.password.value).subscribe(
        data => {
          this.toastr.success(this.messages[this.messageService.getLanguage]['login']['success']);
          this.router.navigate(['/'], { queryParamsHandling: 'preserve'});

        },
        error => {
          this.toastr.error(error.error.message, this.messages[this.messageService.getLanguage]['error']['title']);
          this.loading = false;
        });
  }
}
