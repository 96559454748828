import { NativeDateAdapter } from '@angular/material/core';
import { MatDateFormats } from '@angular/material/core';
import {Injectable} from '@angular/core';

@Injectable()

export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: any): string {
        if (displayFormat === 'input') {
            let day: string = date.getDate().toString();
            day = +day < 10 ? '0' + day : day;
            let month: string = (date.getMonth() + 1).toString();
            month = +month < 10 ? '0' + month : month;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return date.toDateString();
    }

}

export function formatDt(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
            dateInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
        },
            display: {
                dateInput: 'input',
                monthYearLabel: { year: 'numeric', month: 'numeric' },
                dateA11yLabel: { day: 'numeric', month: 'numeric', year: 'numeric' },
                monthYearA11yLabel: { year: 'numeric', month: 'numeric' },
        }
};
