import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {EmailPasswordResetService} from '../_services/email-password-reset.service';
import {scrollToError} from '../_helpers/functions';
import { language } from '../../locale/translation';
import {messages} from '../_helpers/message.dialog';
import {MessageService} from '../_services/message.service';
import {searchParams} from '../_helpers/functions';

@Component({
    selector: 'app-email-password-reset',
    templateUrl: './email-password-reset.component.html',
    styleUrls: ['./email-password-reset.component.scss']
})
export class EmailPasswordResetComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private resetPassword: EmailPasswordResetService,
        public messageService: MessageService
    ) {
    }

    emailpasswordresetForm: FormGroup;
    submitted = false;
    loading = false;
    resCaptcha = false;
    language = language;
    messages = messages;
    selectLang = this.messages[this.messageService.getLanguage];

    ngOnInit() {
        this.emailpasswordresetForm = this.formBuilder.group({
            user: ['', [Validators.required, Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$')]]
        });
    }

    get subFval() {
        return this.emailpasswordresetForm.controls;
    }

    onEmailSend() {
        this.submitted = true;
        const userLang = navigator.language;

        // return for here if form is invalid
        if (this.emailpasswordresetForm.invalid) {
            scrollToError();
            return;
        }
        this.loading = true;

        if (this.resCaptcha) {
            this.resetPassword.emailSend(this.emailpasswordresetForm.get('user').value, userLang.substring(0, 2),
                searchParams(this.router.url)[0]).subscribe(
                (data) => {
                    this.toastr.success(this.selectLang.emailResetPassword.success);
                    this.router.navigate(['/login'], {queryParamsHandling: 'preserve'});
                },
                (error) => {
                    this.router.navigate(['/login'], {queryParamsHandling: 'preserve'});
                    this.toastr.error(error.error.message, this.selectLang.error.title);
                    this.loading = false;
                    this.resCaptcha = false;
                }
            );
        }

    }

    resolved(captchaResponse: string) {
        (captchaResponse !== null && captchaResponse !== '' && typeof captchaResponse === 'string') ?
            this.resCaptcha = true : this.resCaptcha = false;
        // console.log('this.resCaptcha', this.resCaptcha);
        // console.log(`Resolved captcha with response: ${captchaResponse}`);
    }
}
