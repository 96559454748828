import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { headers } from './data.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class CalcCfService {
    constructor(private http: HttpClient) { }

    calcf(cfObj) {
        return this.http.post(environment.BASE_URL_FORM_API + `calcolatoreCf`, cfObj, {headers});
    }

}
