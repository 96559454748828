import { KeyValue } from '@angular/common';
import { Component, Injectable, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { find } from 'rxjs/operators';

@Component({
  selector: 'app-list-box',
  templateUrl: './listbox.component.html',
  styleUrls: ['./listbox.component.scss']
})
@Injectable({ providedIn: 'root' })



export class ListBoxComponent implements OnInit {

  @Input('list') listArray: any;
  @Input ('renderForm') aaa: any;
  @Input ('ifSubmit') submit: any;
  @Input('group') selectGroup: any;
  @Input('groupName') groupName: any;
  @Input('message') lang: string;
  @Input('collectionS') collectionS: any;
  @Input('data') initData: any;
  @Input('mod') mod: boolean;
  constructor() { }

  lastEl: number;
  listObj: object;
  arrDeleted = [];
  arrDisp = [];
  arrDispValidator = [];
  arrGeneral = [];
  arrDato = [];
  el;
  ind = -1;
  addCount = 0;
  objDel = {};
  disabled: boolean;
  allEmpty = (dato) => dato === '' || dato === 'N/D';
  sortObject = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    
    this.checkElem();
    for (let [k, v] of Object.entries(this.listArray)) {
      this.listArray[k].forEach(element => {
        this.arrDato.push(element.dato);
      });
    }
    for (let [k, v] of Object.entries(this.listArray)) {
      if(Number(k) >= 1){
        this.cycleObj(Number(k));
      } else {
        if (!this.arrDato.every(this.allEmpty)){
          this.cycleObj(Number(k));
        }
      }
    }
    this.arrDispValidator.forEach((el) =>{ 
      this.aaa.controls[el.id].setValidators(null);
      this.aaa.controls[el.id].updateValueAndValidity();
    })
    this.addCount = this.lastEl - this.arrDisp.length;
  }

  cycleObj(k: number){
    this.listArray[k].forEach(element => {
      (element.disabled) ? this.disabled = element.disabled : null
      this.arrGeneral.push(element);
      if (element.dato === ''){
        this.el = $(`#row_${k}`).detach();
        this.arrDisp.push(this.el);
        this.arrDispValidator.push(element);
      }
    });
  }

  addElem(event, i){
    event.preventDefault();
    this.checkElem();
    
    this.addCount = this.addCount <0 ? 0 : this.addCount; 

    (this.addCount === this.lastEl) ? this.addCount = this.lastEl : this.addCount++;
    if (this.arrDeleted.length){ 
      $("#list-table").last().append(this.objDel[this.arrDeleted.shift()])
    } else {
      if(this.ind <0){
        this.ind++
      }else{
        this.ind += this.listArray[0].length
      }      
      $("#list-table").last().append(this.arrDisp[this.ind]);
    }
    this.setValidatorsElem($("#list-table").find("tr").last().find('td'));
  }

  delElem(event, i){
    event.preventDefault();
    this.checkElem();
    this.addCount--;
    this.el = $(`#row_${i}`).detach();
    Object.assign(this.objDel, {[i]: this.el});
    this.arrDeleted.push(i);
    this.listArray[i].forEach((el) => {
      this.aaa.controls[el.id].setValue('');
      this.aaa.controls[el.id].setValidators(null);
      this.aaa.controls[el.id].updateValueAndValidity();
      (el.type.includes('check') || el.type.includes('radio')) ?
           $(`#${el.id}`).find('input').prop('checked', false) : null;
    });
  }
  
  setValidatorsElem(obj){
    for (let [k, v] of Object.entries(obj)){
      if (v['id'] !== undefined && v['id'] !== '') {
        this.arrGeneral.filter((e) => e.id === v['id']).forEach((e) => {
          this.aaa.controls[e.id].setValidators(
            Validators.compose([
              (e.required) ? Validators.required : null,
              (e.maxLength) ? Validators.maxLength(e.maxLength) : null, 
              (e.regExp !== null && e.regExp !== '') ? Validators.pattern(e.regExp) : null
            ])
          );
        });
      }
    }
  }

  checkElem(){
    this.lastEl = Object.keys(this.listArray).length - 1;
  }

}
