import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { headers } from './data.service';
import { User } from '../_models';
import { environment } from '../../environments/environment';
import { storageFactory } from 'storage-factory';

const sessionStore = storageFactory(() => sessionStorage);

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private router: any;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStore.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(user: any, password: any) {
        return this.http.post<any>(environment.BASE_URL_FORM_API + `BuildUserEsse3/loginSaveEsse3`,
            {
             user,
             authentication_String : password
            }, {headers})
            .pipe(map(userName => {
                if (userName) {
                    // store user details in local storage to keep user logged in
                    sessionStore.setItem('currentUser', JSON.stringify(userName));
                    sessionStorage.setItem('token_name', userName);
                    this.currentUserSubject.next(userName);
                }
                return userName;
            }));
    }

    logout() {
        // remove user data from local storage for log out
        sessionStore.clear();
        // removeItem
        // localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}

