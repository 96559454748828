import {Component, HostListener, Injectable, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from '../../_services/upload.service';
import { HttpEventType } from '@angular/common/http';
import {MessageService} from '../../_services/message.service';
import {messages} from '../../_helpers/message.dialog';
import { Validators } from '@angular/forms';

declare let $: any;

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
@Injectable({ providedIn: 'root' })
export class InputFileComponent implements OnInit {
  @Input('inputFile') ddd: any;
  @Input('ctrlsub') submitted: boolean;
  @Input('language') language: string;
  @Input('elem') inputData: any;
  @Input('elemID') ID: string;
  @Input('mod') mod_attachment: boolean;

  fileData: File = null;
  fileUploadProgress: number = null;
  previewUrl: any = null;
  mimeType: any = null;
  blobUrl: any = null;
  sliceSize: any = 512;
  regMimeType = /:(.*?);/;
  recieved = true;
  sanitizeURLbase64 = null;
  message = messages;

  constructor(
      private sanitizer: DomSanitizer,
      private uploadService: UploadService,
      private toastr: ToastrService,
      public messageService: MessageService,
      ) { }

  ngOnInit() {
      if (this.inputData.file !== '') {
          this.recieved = true;
          this.mimeType = this.inputData.file.split(this.regMimeType)[1];
          const blob = this.b64toBlob(this.inputData.file.split(',')[1], this.mimeType, this.sliceSize);
          this.blobUrl = URL.createObjectURL(blob);
          this.sanitizeURLbase64 = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobUrl);
      } else { this.recieved = false; }
      
      // Set disabled 
      let checkExist = setInterval(() => {
        if ($(`#${this.ID}`).length) {
            clearInterval(checkExist);
            this.inputData.disabled ? $(`#${this.ID}`).prop('disabled', true) : null;
        }
    }, 10);
      
  }

  fileUpload(fileInput: any) {
    // Reset preview && set recieved variable to false,
    // because we are upload different file from recieved base64
    this.recieved = false;
    this.previewUrl = null;
    this.mod_attachment = false;
    this.fileUploadProgress = 0;
    
    this.fileData = fileInput.target.files[0];

    const formData = new FormData();
    formData.append('file', this.fileData);

    // Max File Size Upload - 10MB
    if (this.fileData.size >= 10485760) {
        // Clear Values
        // this.clearAllData(this.updateValue('', false, true, true));
        this.clearAllData(null);
        // Error Message
        this.toastr.error(this.message[this.messageService.getLanguage].file.size,
          this.message[this.messageService.getLanguage].error.title);
        return;
    }

    // Upload only this type of files and control if name has more extension
    if ((!this.fileData.type.match(/image\/*/)          &&
         !this.fileData.type.match(/application\/pdf/)
        )                                               ||
        (/([.]).*?\1/).test(this.fileData.name) // Check if has double extension
       ) {
          // Clear Values
          // this.clearAllData(this.updateValue('', false, true, true));
          this.clearAllData(null);
          // Error Message
          this.toastr.error(this.message[this.messageService.getLanguage].file.extension,
                            this.message[this.messageService.getLanguage].error.title);
          return;
       }

    // Show preview file
    this.preview();
   
    // Upload Form Service
    this.uploadService.upload(formData, this.inputData.value).subscribe(
        (events) => {
          if (events.type === HttpEventType.UploadProgress) {
            this.fileUploadProgress = Math.round(events.loaded / events.total * 100);
            (this.fileUploadProgress === 100) ? this.mod_attachment = true : null
          } else if (events.type === HttpEventType.Response) {
            this.fileUploadProgress = null;
            this.toastr.success(this.message[this.messageService.getLanguage].file.success);
            // Set value ID for Validator and Submit Object
            this.updateValue(events.body['idAllegato'], false, false, false);
          }
        },
        (error) => {
          this.toastr.error(error.message, this.message[this.messageService.getLanguage].error.title);
          // Don't update value (maintain original "dato" value from origin json), but clear only displayed data
          this.clearAllData(null);
        }
    );
  }

  preview() {
    // Show preview
    this.mimeType = this.fileData.type;

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;

      // Create Blob File
      const blob = this.b64toBlob(this.previewUrl.split(',')[1], this.mimeType, this.sliceSize);
      // Create URL identifier for documents
      this.blobUrl = URL.createObjectURL(blob);
      // Sanitize Url
      this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobUrl);

    };

  }

  b64toBlob(b64Data, contentType, sliceSize) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

clearAttachment() {
  this.clearAllData(this.updateValue('', false, true, true))
}

clearAllData(updateVal) {
  $(`#${this.ID}`).val(''); // Clear name file
  this.mod_attachment = false;
  this.recieved = false;
  this.previewUrl = null;
  this.fileUploadProgress = null;
  updateVal;
}

updateValue(value: string, ev: boolean, model: boolean, view: boolean) {
    this.ddd.controls[this.ID].patchValue(
      value, {
        emitEvent: ev,
        emitModelToViewChange: model,
        emitViewToModelChange: view
    });
    
    this.ddd.controls[this.ID].setValidators(
      Validators.compose([
          (this.inputData.required) ? Validators.required : null
      ])
  )
    this.ddd.controls[this.ID].updateValueAndValidity();
}

  get fval() { return this.ddd.controls; }

}
