import {Component, Injectable, Input, OnInit} from '@angular/core';
import {MessageService} from '../../_services/message.service';
import {messages} from '../../_helpers/message.dialog';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
@Injectable({ providedIn: 'root' })
export class InputTextComponent implements OnInit {
  @Input('formInput') bbb;
  @Input('ctrlsub') submitted: boolean;
  @Input('language') language: string;
  @Input('elem') inputData: any;
  @Input('elemID') ID: string;

  RegExp = '';
  message = messages;

  constructor(
      public messageService: MessageService
  ) { }

  ngOnInit() {

    this.inputData.map((el) => {
      // Transform into object
      this.inputData = el;
    });

  }

  get fval() { return this.bbb.controls; }

}
