import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services';
import { environment } from '../../environments/environment';
import { CallDataForm } from '../_services/form.service';
import { ConfirmEmailValidator } from '../_services/email-validator.service';
import { ConfirmPasswordValidator } from '../_services/password-validator.service';
import { CalcCfService } from '../_services/calc-cf.service';
import { MessageService } from '../_services/message.service';
import { language } from '../../locale/translation';
import { messages } from '../_helpers/message.dialog';
import { formatDt } from '../_services/datepicker-format';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { hasParams, searchParams, trimValues, getParam, scrollToError, scrollToErrorToken } from '../_helpers/functions';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

declare let $: any;


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ]
})
export class RegisterComponent implements OnInit {

    public resultsNationOption: any = [];
    optionProvArray = [];
    optionComuneArray = [];
    obj = {};
    invalidCf = false;
    warning = false;
    token = false;
    invalidEmail = true;
    select = ['codiceProvincia', 'codiceCatastale', 'luogo'];
    // tslint:disable-next-line:max-line-length
    stringRegExp: any = '[^*`~.<>°#;:"/[\\]|{}()=_+-?&%@$£!]*[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð,\'-]*[^*`~.<>°#;:"/[\\]|{}()=_+-?&%@$£!]';
    codFiscReg = '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$';
    labelMap: any = ['nome', 'cognome', 'sesso', 'dataNascita', 'codiceCatastale'];
    refName: any = ['Nome', 'Cognome', 'Sesso', 'Data di nascita', 'Comune'];
    regExpNumber = (/^[0-9]*$/);
    isValidParam: any = (
        hasParams() &&
        ( searchParams(this.router.url)[0] !== ''                    &&
            searchParams(this.router.url)[0] !== undefined           &&
            this.regExpNumber.test(searchParams(this.router.url)[0])
        )
    );

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private calcCodFisc: CalcCfService,
        private service: CallDataForm,
        private toastr: ToastrService,
        public messageService: MessageService,
        private dateAdapter: DateAdapter<Date>
    ) { this.dateAdapter.setLocale('it'); }

    registerForm: FormGroup;
    loading = false;
    submitted = false;
    selectedValue = '';
    language = language;
    messages = messages;

    ngOnInit() {

        this.getNation();

        this.registerForm = this.formBuilder.group({
            nome: ['', [Validators.required, Validators.pattern(this.stringRegExp)]],
            cognome: ['', [Validators.required, Validators.pattern(this.stringRegExp)]],
            sesso: ['', Validators.required],
            dataNascita: ['', Validators.required],
            codiceFiscale: ['', [Validators.required, Validators.pattern(this.codFiscReg)]],
            oneShot: ['0'], // User is not registered so, oneShot is always be '0' for Registration
            codiceNazione: ['', Validators.required],
            codiceProvincia: ['', Validators.required],
            codiceCatastale: ['', Validators.required],                // comune
            luogo: [{value: '', disabled: true},                       // località
                        [Validators.required, Validators.pattern(this.stringRegExp)]
                   ],
            users: this.formBuilder.group({
                user: ['', [Validators.required, Validators.email]],
                confirmEmail: ['', [
                        Validators.required,
                        Validators.email,
                        ConfirmEmailValidator('user')
                    ]
                ],
                authentication_String: ['', [Validators.required, Validators.minLength(6)]],
                confirmPassword: ['', [
                    Validators.required,
                    Validators.minLength(6),
                    ConfirmPasswordValidator('authentication_String')
                ]
                ],
                attivo: ['0']
            }),
            privacy: [false, Validators.requiredTrue],
            linguaCompilazioneDomanda: 'it',
            token: ['', Validators.required]
        });

    }
    
    getNation() {
        const ep = environment.EP_NATION_REGISTER;
        this.service.fetchData(ep).subscribe(data => {
            this.resultsNationOption = data;
            this.resultsNationOption.unshift('');
        }, (err) => console.error(err));
    }

    selectNation(natId: string, identifier): void {
        const ep = environment.BASE_URL_API + 'esse3/P01_PROV/';
        if (natId === '1') {
            this.selectedValue = '';
            // Call service and enable controls
            this.callService(ep, identifier);
            this.enableControl();
        } else {
            // Empty Arrays, value and disable controls
            this.optionProvArray = [];
            this.optionComuneArray = [];
            this.selectedValue = '';
            this.disableControl();
        }
    }

    selectProvincia(provId: string, identifier): void {
        // Empty Array
        this.optionComuneArray = [];
        const ep = environment.BASE_URL_API + 'esse3/P01_COMU/' + provId;
        this.callService(ep, identifier);
    }

    // Call Service
    callService(ep, identifier) {
        this.service.fetchData(ep).subscribe(data => {
            data.map((el: any) => {
                if (identifier.toLowerCase().includes('nazione')) {
                    this.optionProvArray.push(el);
                } else {
                    this.optionComuneArray.push(el);
                }
            });
        }, (err) => console.error(err));
    }

    sendToken() {
        this.token = true;
        this.registerForm.controls.token.setValue('');
        this.registerForm.controls.token.updateValueAndValidity();
        if(!this.subFval.confirmEmail.errors && !this.subFval.user.errors){
            this.userService.token({
                    email: this.registerForm.controls.users['controls']['confirmEmail'].value
                }, this.messageService.getLanguage).subscribe(
                    (data) => {
                        this.toastr.success(this.messages[this.messageService.getLanguage].token.check, this.messages[this.messageService.getLanguage].token.success, {timeOut: 10000});
                    },
                    (error) => {
                        this.toastr.error( error.error.message,  this.messages[this.messageService.getLanguage].error.title);
                        this.loading = false;
                        this.token = false;
                    }
                );
        } else {
            setTimeout(() => {scrollToErrorToken();},10)
        }
    }

    // Form Controls
    get fval() { return this.registerForm.controls; }
    get subFval() { return this.registerForm.controls.users['controls']; }

    // Disable Select Control onChange Select
    disableControl() {
        this.select.forEach((el) => { (el !== 'luogo') ? this.disable(el) :  this.enable(el); });
    }

    // Enable Control onChange Select
    enableControl() {
        this.select.forEach((el) => { (el !== 'luogo') ? this.enable(el) :  this.disable(el); });
    }

    disable(el) {
        this.registerForm.controls[el].disable();
        this.registerForm.controls[el].setValidators(null);
        this.registerForm.controls[el].updateValueAndValidity();
    }
    enable(el) {
        this.registerForm.controls[el].enable();
        this.registerForm.controls[el].setValidators(
            (el === 'luogo' || el === 'nome' || el === 'congnome') ? Validators.compose(
                [Validators.required, Validators.pattern(this.stringRegExp)]
            ) : Validators.required
        );
        this.registerForm.controls[el].updateValueAndValidity();
    }

    calcCf() {
        // Get Codice Fiscale Nazione
        const codFiscNazione = this.resultsNationOption.filter((filterEl) =>
            filterEl.value === this.registerForm.get('codiceNazione').value);

        // Get Comune COD
        const filterCod: any = this.optionComuneArray.filter((filterEl) =>
            filterEl.option === this.registerForm.get('luogo').value
        );
        // If nation is different to Italy, remove Comune from validation of CF
        if (codFiscNazione.length > 0 && codFiscNazione[0].value !== undefined) {
            if (codFiscNazione[0].value !== '1') {
                this.labelMap.splice(4, 1);
                this.refName.splice(4, 1);
            } else {
                if (this.refName.indexOf('Comune') === -1) {
                    this.labelMap.push('codiceCatastale');
                    this.refName.push('Comune');
                }
            }
        }

        const result = [];
        // Get element that haven't empty values for Validation
        const validationElem = this.labelMap.map((el) => this.registerForm.controls[el].value !== '');
        // Push on array the index of array that are false in validationElem Array, for create array of index and display error
        validationElem.map((el, i) => {(validationElem[i] === false) ? result.push(i) : null; });
        // Use indexes of result array and compare with object refName for get the label to display it on toastbar
        const resultValidator: any = result.map((el) => this.refName[el]);

        // Display Errors in template
        this.labelMap.map((el) => {
            if (this.fval[el].errors) {
                this.invalidCf = true;
                this.warning = true;
                setTimeout(() => {this.invalidCf = false; this.warning = false; }, 5000);
            }
        });

        if (validationElem.every(v => v === true)) {
            this.calcCodFisc.calcf({
                nome: this.registerForm.get('nome').value,
                cognome: this.registerForm.get('cognome').value,
                sesso: this.registerForm.get('sesso').value,
                nascita: this.registerForm.get('dataNascita').value,
                codiceLuogoNascita: (filterCod.length) ? filterCod.map((el) => el.value)[0] :
                    codFiscNazione.map((el) => el.value)[0],
                codiceFiscale: this.registerForm.get('codiceFiscale').value
            }).subscribe(
                (data) => {
                    this.registerForm.controls.codiceFiscale.setValue(data['codiceFiscale']);
                },
                (error) => {
                    this.toastr.error(error, this.messages[this.messageService.getLanguage].error.title);
                    this.loading = false;
                }
            );
        } else {
            scrollToError();
            this.toastr.warning(this.language[this.messageService.getLanguage].register.errorFiscalCode +
                resultValidator.map((el) => el), this.messages[this.messageService.getLanguage].warning.title);
        }

    }

    dtChange(event) {
        // console.log(event.target.value, formatDt(event.target.value));
        this.registerForm.controls.dataNascita.setValue(
            formatDt(event.target.value),
            {
                emitEvent: false,
                emitModelToViewChange: false,
                emitViewToModelChange: false
            });
    }

    onFormSubmit() {
        this.submitted = true;

        // Check if has params and it is valid
        if (!this.isValidParam) {
            this.toastr.error(
                this.messages[this.messageService.getLanguage].home.parameterNotValid,
                this.messages[this.messageService.getLanguage].home.title
            );
            return;
        }

        // Trim spaces to registerForm controls values - in this position bypass regex space control
        trimValues(this.registerForm);

        // Return for here if form is invalid
        if (this.registerForm.invalid) {
            scrollToError();
            return;
        }

        // Add Description of Nazione and Provincia to registerForm
        this.registerForm.addControl(
            'nazione' , this.formBuilder.control($('#nazione').find('option:selected').text())
        );
        this.registerForm.addControl(
            'provincia', this.formBuilder.control($('#provincia').find('option:selected').text())
        );

        // Select Values to send
        if (this.optionComuneArray.length) {
            // Get comune Cod by Località input value
            const filterElem = this.optionComuneArray.filter((filterEl) =>
                filterEl.option === this.registerForm.get('luogo').value
            );
            // Set codiceCatastale
            this.registerForm.controls.codiceCatastale.setValue(
                filterElem.map((el) => el.value)[0], {emitModelToViewChange: false});

        } else {
            //  Set code Nation to Comune
            this.registerForm.controls.codiceCatastale.setValue(
                this.registerForm.controls.codiceNazione.value, {emitModelToViewChange: false});
        }

        // Set 0 or 1 to privacy
        this.registerForm.controls.privacy.setValidators(null); // Not display error because value is changed from boolean to string
        let swVal = (this.registerForm.get('privacy').value === true)  ? '1' : '0';
        this.registerForm.controls.privacy.setValue(swVal,
            {
                emitEvent: false,
                emitModelToViewChange: false,
                emitViewToModelChange: false
            });

        this.loading = true;
    
        // INFO: getRawValue() take also disabled elements
        this.userService.register(Object.assign(this.registerForm.getRawValue(), {
            source: (getParam(this.router.url).hasOwnProperty(['source'])) ? encodeURIComponent(getParam(this.router.url)['source']) : ''
          })).subscribe(
            (data) => {
                this.toastr.success(this.messages[this.messageService.getLanguage].register.success);
                this.router.navigate(['/login'], {queryParamsHandling: 'preserve'});
            },
            (error) => {
                this.toastr.error(error.error.message,  this.messages[this.messageService.getLanguage].error.title);
                this.loading = false;
            }
        );

    }

}
