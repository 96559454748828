import {Component, HostListener, Injectable} from '@angular/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from './_services';
import {User} from './_models';
import {fromEvent, merge, Observable, of} from 'rxjs';
import {mapTo} from 'rxjs/operators';
import {messages} from './_helpers/message.dialog';
import {MessageService} from './_services/message.service';
import { CallDataForm } from './_services/form.service';
import { environment } from 'src/environments/environment';
import { searchParams, groupBy } from './_helpers/functions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
@Injectable({
    providedIn: 'root'
  })
export class AppComponent {
    currentUser: User;
    online: Observable<boolean>;
    messageConnection: string;
    messages = messages;
    offline = false;
    logo: any;
    currentRoute: string;
    pageGrp:any;
    err:any;
    defaultLogo = "./assets/Luiss-Logo.svg";

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        public messageService: MessageService,
        private service: CallDataForm
    ) {

        this.logo = new BehaviorSubject<any>('');

        this.service.fetchData( environment.BASE_URL_FORM_API + 'uisettings' + '/' + searchParams(this.router.url)[0]).subscribe(dt => {
            try {
                setTimeout(() => {
                    this.pageGrp = this.groupData(dt, 'page');   
                }, 0);
            } catch (e) {this.err = true; this.logo.next(this.defaultLogo)}
        },
        err => { this.err = true; this.logo.next(this.defaultLogo) }); 
        
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.logo = new BehaviorSubject<any>('');
            }

            if (event instanceof NavigationEnd) {
                if (event.id === 1){
                    let dataExist = setInterval(() => {
                        if (this.pageGrp) {
                            clearInterval(dataExist);
                            this.logo.next(this.getData(event, 'logo'))
                        }
                    }, 10);
                } else {
                    (!this.err) ?  this.logo.next(this.getData(event, 'logo')) : this.logo.next(this.defaultLogo);
                }
                
            }

            if (event instanceof NavigationError) {
                this.logo.next(this.defaultLogo);
            }
        });


        this.online = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        );

        this.networkStatus();

        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

        // If user is logged not show login and register component
        if (location.pathname !== '/' && this.currentUser) {
            this.router.navigate(['/']);
        } else {}

    }


    public get getLogo(): any {
        return this.logo.value
    }

    public networkStatus() {
        this.online.subscribe(value => {
            this.messageConnection = `Connection ${value}`;
            (!value) ? (
                this.offline = true,
                this.toastr.error(this.messages[this.messageService.getLanguage].connection.error, 
                                  this.messages[this.messageService.getLanguage].connection.title)
                ) : this.offline = false;
        });
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login'], {queryParamsHandling: 'preserve'});
    }

    public selectLanguage(event): void {
        this.messageService.languageSelector.next(event.target.value);
    }

    groupData = (list, key) => {
        const newGroup = {};
        list.forEach(item => {
            const newItem = Object.assign({}, item);
            delete newItem[key];
            newGroup[item[key]] = newGroup[item[key]] || [];
            newGroup[item[key]].push(newItem);
        });
        return newGroup;
    };

    getData(event, item) {
        if (event.url.split('/')[1].split('?')[0] !== undefined && event.url.split('/')[1].split('?')[0] !== '') {
           return this.pageGrp[event.url.split('/')[1].split('?')[0]][0][item]
        } else if (event.url.split('/')[1].split('?')[0] === ''){
            return this.pageGrp['home'][0][item]
        } else {
             return this.defaultLogo;
        }
    }

}
