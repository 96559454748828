import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class DateService {
    private subject = new BehaviorSubject('');

    setDate(date: any) {
        this.subject.next(date);
    }

    getDate(): Observable<any> {
        return this.subject;
    }
}
