import {Component, Injectable, Input, OnInit} from '@angular/core';
import {MessageService} from '../../../_services/message.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
@Injectable({ providedIn: 'root' })

export class TooltipComponent implements OnInit {

  @Input('message') text_message:string;
  open = false;

  constructor(
      public messageService: MessageService
  ) { }

  ngOnInit() {
  }


}
