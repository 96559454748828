import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeHtmlPlaceholder'})

export class SafeHtmlPipePlaceHolder implements PipeTransform  {
    constructor() {}
    transform(value) {
        if (!(value && (/(<([^>]+)>)/i.test(value)))) {
            return value;
        } else {
            return value.replace(/<[^>]*>/g, '');
        }
    }
}
