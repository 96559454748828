import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services';
import { CallDataForm } from '../_services/form.service';
import { SubmitService } from '../_services/submit.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../_services/message.service';
import { messages } from '../_helpers/message.dialog';
import { DateService } from '../_services/date-service';
import { 
  scrollToError,
  getTimestamp,
  isEmptyObj,
  checkAllObj,
  hasParams,
  searchParams,
  trimValues,
  groupBy, 
  getParam} from '../_helpers/functions';
import {storageFactory} from 'storage-factory';

// const sessionStore = storageFactory(() => sessionStorage);
//DEPLOY
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  private currentUser;
  public resultsArray: any = [];

  renderForm: FormGroup;
  message = messages;
  loading = false;
  spinner = false;
  submitted = false;
  selectLoaded = false;
  data: any;
  qParam: any;
  realTime: any;
  ii = 0;
  iii = 0;
  obj = {};
  selectIdArr = [];
  finalArrSel=[];
  mainArr = []; // groups in json
  htmlArr = [];
  listArray = [];
  listBoxArr = [];
  objValidator = {};
  testArrGroup = [];
  arrGroup = [];
  collectSelectG = [];
  collectSubSelG = [];
  indexArr = [];
  splitNameGrp = [];
  completeArr = [];
  allGroup = [];
  finalArr = [];
  regExpNumber = (/^[0-9]*$/);
  isValidParam: any = (
        hasParams() &&
        ( searchParams(this.router.url)[0] !== ''                   &&
          searchParams(this.router.url)[0] !== undefined            &&
          this.regExpNumber.test(searchParams(this.router.url)[0] )
        )
  );

  constructor(
      private service: CallDataForm,
      private submitService: SubmitService,
      private formBuilder: FormBuilder,
      private router: Router,
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService,
      private toastr: ToastrService,
      public  messageService: MessageService,
      private dtService: DateService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    // this.qParam = JSON.parse(localStore.getItem('paramsVal'));
    this.qParam = messageService.getParam;
    // this.currentUser = localStore.getItem('currentUser') ? JSON.parse(localStore.getItem('currentUser')) : '';
  }

  ngOnInit() {
    // Check if Has Param in Query String and is number and check login param then Call Service
    if (this.isValidParam) {
      if (this.qParam !== '' && this.qParam === searchParams(this.router.url)[0]) {
      this.spinner = true;
      const ep = environment.BASE_URL_FORM_API + 'v2/forms/' + this.qParam + '/' + this.currentUser + '?t=' + getTimestamp();
      // Call NTP Server - getRealTime
      this.service.fetchData(environment.BASE_URL_FORM_API + 'variabile/datetime').subscribe(dt => {
            this.realTime = dt.realTime;
      });

      // Call Form Data
      this.service.fetchData(ep).subscribe(data => {
              this.data = data;
              // Check Validity Form
              if (this.checkValidity(data.startDate, this.realTime, data.endDate)) {
                  // Render Form
                  try {
                      this.renderFormData(data);
                  } catch (e) {
                      this.sWW('home', e.message); // Success Call, but are DATA CORRUPTED or MALFORMED JSON 
                  }
                  console.log('%c RES ', 'background: #222; color: #bada55' , data);
              } else { this.sWW('home', 'session'); }
          },
          err => { this.sWW('home', err.error.message); },
          () => this.spinner = false);
      } else {
        this.sWW('home', 'session');
      }
    } else {
      this.sWW('home', 'parameterNotValid');
    }

  }

  renderFormData(data) {
    // Form Groups - Blocchi Dati
    data.group.map((el: any) => {
      this.mainArr.push(el);
    });

    // Html Group of contents - if exist, render it
    (data.hasOwnProperty('html') && data.html.length) ?
      data.html.map((el: any) => {
        this.htmlArr.push(el);
      }) : null

    this.resultsArray = this.resultsArray.concat(this.mainArr);

    // Get data ID's of Components for Validators
    this.resultsArray.map((el: any) => {

      el.components.forEach((ele, index) => {
        // collect only select ID
        (ele.type === 'select' && ele.visible) ? this.selectIdArr.push(ele.id) : null;
          // CREATE OBJ OF FORM GROUP - Insert Value and control options for Validation
          this.objValidator[ele.id] = [ // FEATURE - [ele.id] => [getSubComponentsPKs(ele)]
            {
              value: (ele.dato && ele.dato !== '' && ele.visible) ? ele.dato : '',   // Value
              disabled: ele.disabled                                                 // Disabled
            },
            Validators.compose([
              (ele.type === 'file' && ele.dato !== '') ? null :                      // Set Allegato not required if BE send base64
              (ele.required && ele.visible) ? Validators.required : null,            // Required
              (ele.regExp && ele.visible) ? Validators.pattern(ele.regExp) : null,   // Regex
              (ele.maxLength && ele.maxLength > 0 && ele.visible) ? Validators.maxLength(ele.maxLength) : null // maxLength input type text UPDATE Fn -> Binding Validator
            ])
          ];

          //ListBox Table - Grouping list-box's elements
          (ele.hasOwnProperty('tableName') && ele.tableName !== '') ? (this.listArray.push(ele), this.listBoxArr.push(ele)) : null;

          // START GROUPING SELECT
          const startGroup: any = (
              ele.pkFilteredByValueOfComponent === null &&
              ele.pkOnChangeItemFillComponent !== ''
          );
          const endGroup: any = (
              ele.pkFilteredByValueOfComponent !== ''  &&
              ele.pkOnChangeItemFillComponent === null
          );

          if (startGroup && endGroup) {} else {
              this.testArrGroup.push(ele);
              this.indexArr.push(this.ii++);
              if (endGroup) {
                  this.iii++;
                  this.arrGroup = this.testArrGroup.splice(0, this.indexArr.length);
                  // Create array with all group for select component
                  this.allGroup.push(this.arrGroup);
                  // Get Group name based on first select of group
                  this.arrGroup.forEach((elem, i) => {
                      elem.name = 'group' + (this.iii - 1) + '-' + i;
                      this.splitNameGrp.push((i === 0) ? elem.name.split('-')[0] : '');
                      this.splitNameGrp = this.splitNameGrp.filter((el) => el !== '');
                      this.splitNameGrp = [...new Set(this.splitNameGrp)];
                  });

                  this.splitGrp(this.arrGroup);
                  this.ii = 0;
                  this.indexArr = [];
              }
          }
          // END GROUPING SELECT

        });

    });

    //ListBox Table - Remove duplicated elements of table from original array
    (this.listBoxArr.length) ? this.resultsArray.splice(this.resultsArray.findIndex(ele => ele.hasOwnProperty('tableName') && ele.tableName !== ''), 1) : null;
    
    //ListBox Table - Sorting list-box's elements
    const listGroupElem = groupBy('tableRow');
    this.listArray = listGroupElem(this.listArray);

    // For every group push its name and its array with select and subselect into finalArr
    this.splitNameGrp.map((el, i) => {
       this.finalArr.push({[el] : this.completeArr[i]});
    });

    // Validators
    this.renderForm = this.formBuilder.group(this.objValidator);
  }


  // FEATURE - NOT YET IMPLEMENTED!!! - Return Subcomponents PK for Form's Validation and Values - only for checkgroup component
  getSubComponentsPKs(ele) {
    if (ele.type.includes('check')){
      let i = ele.subComponents.length -1;
      return ele.subComponents.map((e) => e.id)[i];
  } else {return ele.id} }

  splitGrp(arr) {
          let tmpArr = [];
          arr.map((elem, index) => {
              (elem.type === 'select' && index > 0) ? this.collectSelectG.push(elem.id) : null;
              (elem.type !== 'select') ? this.collectSubSelG.push(elem.id) : null;
          });
          // Push element for select array
          tmpArr.push({select: this.collectSelectG.splice(0, this.collectSelectG.length)});
          // Push element for other components (subselect) if exists
          (this.collectSubSelG.length) ? tmpArr.push({subselect: this.collectSubSelG.splice(0, this.collectSubSelG.length)}) : null;
          // Compose array with select and if exist subselect
          this.completeArr.push(tmpArr);
  }

  // sWW = something Went Wrong
  sWW(typ: string, event: string) {
    this.authenticationService.logout();
    this.router.navigate(['/not-valid'], { queryParamsHandling: 'preserve'});
    this.toastr.error(
   (this.message[this.messageService.getLanguage][typ][event] !== void 0) ?
    this.message[this.messageService.getLanguage][typ][event] : event,
             this.message[this.messageService.getLanguage].error.title);
  }

  checkValidity(sd, cd, ed) {
    // sd = start date; cd = current date; ed = end date; - with timestamp (HH:MM:SS)
    if (sd && cd && ed) {
        if ((cd > ed) || (cd < sd)) {                   // form expired or not present yet
             this.dtService.setDate(sd + '_' + ed);     // set date for validity form
             return;
        } else {
             return true;                               // valid form
        }
    } else { return; }
  }

  onRenderFormSubmit() {
      console.log('TEST dataLayer') 
      this.finalArrSel = [];
      this.submitted = true;

      // Trim spaces to renderForm controls values - in this position bypass regex space control
      trimValues(this.renderForm);

      if (this.renderForm.invalid) {
        scrollToError();
        this.spinner = false;
        return;
      }

      this.selectIdArr.map((o) => {(this.renderForm['value'][o] !== '') ? this.finalArrSel.push(this.renderForm['value'][o]) : null});
      this.finalArrSel.map((e, i) => {(e === undefined) ? this.finalArrSel[i] = {} : null}); // dato N/D

      // Submit Form Service
      (checkAllObj(this.finalArrSel)) ?
      (this.spinner = true,
       this.loading = true,
       this.submitService.submit(Object.assign(this.renderForm.getRawValue(), {
           idEvento: +searchParams(this.router.url)[0],
           idPersona: this.currentUser,
           lingua: this.messageService.getLanguage,
           source: (getParam(this.router.url).hasOwnProperty(['source'])) ? encodeURIComponent(getParam(this.router.url)['source']) : ''
         })).subscribe(
               (data) => {

                (<any>window).dataLayer = (<any>window).dataLayer || [];
                (<any>window).dataLayer.push({
                'event': 'thank_you',
                'formLocation': 'forms',
                'course_id': '$idCorso',
                'course_name': '$nomeCorso',
                });
                 this.toastr.success(
                   (!isEmptyObj(this.data.evento.success_message)) ? 
                     this.data.evento.success_message[this.messageService.getLanguage] :
                     this.message[this.messageService.getLanguage].home.success
                   );
                 // Redirect to Home and Logout
                 this.authenticationService.logout();
                 this.router.navigate(['/login'], { queryParamsHandling: 'preserve'});
               },
               (error) => {
                 this.toastr.error(error.error.message, this.message[this.messageService.getLanguage].error.title, {timeOut: 10000});
                 this.loading = false;
                 this.spinner = false;
               },
               () => this.spinner = false
         )) : null



  }

}
