export const language = {
    it: {
        login: {
            user: `Utente`,
            password: `Password`,
            register: `Se non sei ancora registrato`,
            cta_register: `Registrati`,
            for_passwd: `Password dimenticata?`,
            cta_passwd: `Reset Password`
        },
        register: {
            title: `Registrazione Utente`,
            nome: `Nome`,
            cognome: `Cognome`,
            sesso: `Sesso`,
            dataNascita: `Data di nascita`,
            nazione: `Nazione di nascita`,
            provincia: `Provincia di nascita`,
            comune: `Comune di nascita`,
            luogo: `Località di nascita`,
            codiceFiscale: `Codice fiscale`, 
            user: `E-mail`,
            confirmEmail: `Conferma e-mail`,
            password: `Password`,
            confirmPassword: `Conferma password`,
            required: `Dato richiesto`,
            notValid: `Dato non valido`,
            privacy: `<span>Il sottoscritto dichiara di aver preso visione dell'informativa sul trattamento dei dati personali al seguente <a href='./assets/doc/Luiss_Informativa_piattaforma_forms.pdf' target='_blank'>link</a></span>`,
            buttonRegistration: 'Registrati',
            buttonFiscalCod: 'Calcola',
            errorFiscalCode: `Compilare i seguenti campi: `,
            token: `Token`,
            placeholderToken: `Inserisci il token ricevuto nella mail`,
            tokenBtn: `Invia token`
        },
        emailResetPassword: {
            title: `Inserire l'e-mail per effettuare il reset della password`,
            placeholderEmail: `E-mail`,
            required: `L'e-mail è obbligatoria`,
            validEmail: `Inserire una e-mail valida`
        },
        resetPassword: {
            title: `Cambio password`,
            placeholderPassword: `Password`,
            placeholderConfirmPassword: `Conferma password`,
            passwordRequired: `Password obbligatoria`
        },
        commonMessage: {
            backLogin: `Vai al login`,
            passwordRequired: `Conferma la tua password`,
            minlength: `La password deve avere almeno 6 caratteri`,
            notMatch: `Le password non sono uguali`
        },
        cookieBanner: {
            text: `Al fine di migliorare la tua esperienza di navigazione, questo sito utilizza i cookie di profilazione di terze parti. Chiudendo questo banner o accedendo ad un qualunque elemento sottostante acconsenti all’uso dei cookie. Se vuoi saperne di più o negare il consenso a tutti o solo ad alcuni cookie,`,
            link: `leggi la privacy .`
        }
    },
    en: {
        login: {
            user: `User`,
            password: `Password`,
            register: `If you're not register yet`,
            cta_register: `Register`,
            for_passwd: `Forgot password?`,
            cta_passwd: `Reset Password`
        },
        register: {
            title: `User registration`,
            nome: `Name`,
            cognome: `Surname`,
            sesso: `Gender`,
            dataNascita: `Birthdate`,
            nazione: `Country of birth`,
            provincia: `District of birth`,
            comune: `Municipality of birth`,
            luogo: `Place of birth `,
            codiceFiscale: `Fiscal code`,
            user: `Email`,
            confirmEmail: `Confirm email`,
            password: `Password`,
            confirmPassword: `Confirm password`,
            required: `Data required`,
            notValid: `Not valid data`,
            privacy: `<span>The undersigned declares to have read the information on the processing of personal data at the following <a href='./assets/doc/Luiss_Informativa_piattaforma_forms_Eng.pdf' target='_blank'>link</a><span>`,buttonRegistration: `Sign in`,
            buttonFiscalCod: `Calculate`,
            errorFiscalCode: `Fill in the following fields: `,
            token: `Token`,
            placeholderToken: `Enter the token received in the mail`,
            tokenBtn: `Send token`
        },
        emailResetPassword: {
            title: `Enter the email to reset the password`,
            placeholderEmail: `Email`,
            required: `Email is required`,
            validEmail: `Enter a valid e-mail`
        },
        resetPassword: {
            title: `Change password`,
            placeholderPassword: `Password`,
            placeholderConfirmPassword: `Confirm password`,
            passwordRequired: `Password required`,
            required: `Confirm your password`,
            minlength: `The password must be at least 6 characters long`,
            notMatch: `Passwords doesn't match`
        },
        commonMessage: {
            backLogin: `Back to login`,
            passwordRequired: `Confirm your password`,
            minlength: `The password must be at least 6 characters long`,
            notMatch: `Passwords doesn't match`
        },
        cookieBanner: {
            text: `In order to improve your browsing experience, this site uses third-party profiling cookies. By closing this banner or accessing any underlying element, you consent to the use of cookies. If you want to know more or opt out of all or some cookies,`,
            link: `read cookie policy.`
        }
    }
};
