import {Component, Injectable, OnInit} from '@angular/core';
import {MessageService} from '../../_services/message.service';
import {messages} from '../../_helpers/message.dialog';
import {DateService} from '../../_services/date-service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
@Injectable({ providedIn: 'root' })
export class NotFoundComponent implements OnInit {

  message = messages;
  typ: string;

  constructor(
      public messageService: MessageService,
      private route: ActivatedRoute
  ) {
    this.typ = this.route.routeConfig.component.name;
  }

  ngOnInit() {
  }

}
