import { Component, Injectable, Input, OnInit} from '@angular/core';
import {MessageService} from '../../_services/message.service';
import {messages} from '../../_helpers/message.dialog';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
@Injectable({ providedIn: 'root' })
export class InputCheckboxComponent implements OnInit {
  @Input('inputCheck') fff: any;
  @Input('ctrlsub') submitted: boolean;
  @Input('language') language: string;
  @Input('elem') inputData: any;
  @Input('elemID') ID: string;

  message = messages;

  constructor(
      public messageService: MessageService
  ) { }


  ngOnInit() {
    this.inputData.map((el) => {
      // Transform into object
      this.inputData = el;
    });

    if (this.inputData.dato !== '') { // REWORK
      setTimeout(() => {
        this.fff.controls[this.ID].patchValue(this.inputData.dato);
        this.fff.controls[this.ID].updateValueAndValidity();
      });
   }

  }

  onChangeCategory(event, cat: any) {
      // Set value for checkbox based on id
      this.fff.controls[cat].patchValue((event.target.checked) ? '1' : (this.inputData.required) ? '' : '0');
      this.fff.controls[cat].updateValueAndValidity();

    // Restore initial Array with category
    /*(this.tmpArr.length <= 0 || this.tmpArr.category <= 0) ? this.tmpArr = { category: [] } : null;

    // Control if checked and prevent duplicate items on array
    let index = this.tmpArr.category.indexOf(cat);
    (event.target.checked) ?
     this.tmpArr.category.push(cat) :
     (index > -1)  ?
     this.tmpArr.category.splice(index, 1) : null;

     // If we haven't selected a choice, empty array
    (this.tmpArr.category <= 0) ? this.tmpArr = '' : null;

    // onChange insert new value on tmp Array for Validator and Submit
    this.fff.controls[this.ID].patchValue(this.tmpArr);
    console.log(this.tmpArr);*/

  }

  get fval() { return this.fff.controls; }

}
