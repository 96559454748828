import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { headerUpload } from './data.service';

@Injectable({ providedIn: 'root' })


export class UploadService {
    constructor(private http: HttpClient) { }

    upload(file, identifier) {
        return this.http.post(environment.BASE_URL_FORM_API + `uploadFile`, file, {
            headers: headerUpload,
            params: { allegatoType: identifier },
            reportProgress: true,
            observe: 'events'
        });
    }

}
