import { Component, OnInit } from '@angular/core';
import {messages} from '../../_helpers/message.dialog';
import {MessageService} from '../../_services/message.service';
import {DateService} from '../../_services/date-service';
import {formatDateIt} from '../../_helpers/functions';

@Component({
  selector: 'app-not-valid',
  templateUrl: './not-valid.component.html',
  styleUrls: ['./not-valid.component.scss']
})
export class NotValidComponent implements OnInit {

  message = messages;
  startDate = '';
  endDate = '';
  typ: string;

  constructor(
      public messageService: MessageService,
      private dtService: DateService
  ) {
    
      const sd = this.dtService.getDate()['value'].split('_')[0];
      const ed = this.dtService.getDate()['value'].split('_')[1];

      (sd || ed) ? (
        this.startDate = formatDateIt(sd) + ' ' + sd.split(' ')[1],
        this.endDate = formatDateIt(ed) + ' ' + ed.split(' ')[1]
      ) : null
      
  }

  ngOnInit() {
  }

}
