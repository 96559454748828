import {Component, Injectable, Input, OnInit} from '@angular/core';
import {MessageService} from '../../_services/message.service';
import {messages} from '../../_helpers/message.dialog';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
@Injectable({ providedIn: 'root' })
export class TextareaComponent implements OnInit {
  @Input('formInput') ttt;
  @Input('ctrlsub') submitted: boolean;
  @Input('language') language: string;
  @Input('elem') textData: any;
  @Input('elemID') ID: string;

  RegExp = '';
  message = messages;

  constructor(
      public messageService: MessageService
  ) { }

  ngOnInit() {

    this.textData.map((el) => {
      // Transform into object
      this.textData = el;
    });

  }

  get fval() { return this.ttt.controls; }

}
