import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';
import {getParam, searchParams} from './functions';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        
        // Landing page, search params
        this.router.navigate(['/login'], {
            queryParams:
                {
                  id_evento: searchParams(this.router.url)[0],
                  lang: (getParam(this.router.url).hasOwnProperty(['lang'])) ? encodeURIComponent(getParam(this.router.url)['lang']) : null,
                  source: (getParam(this.router.url).hasOwnProperty(['source'])) ? encodeURIComponent(getParam(this.router.url)['source']) : null
                }
        });

        return false;
    }
}
