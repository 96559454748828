import { Component, Injectable, Input, OnInit } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-render-form',
  templateUrl: './render-form.component.html',
  styleUrls: ['./render-form.component.scss']
})
@Injectable({ providedIn: 'root' })


export class RenderFormComponent implements OnInit {
  @Input ('renderForm') aaa: any;
  @Input ('ifSubmit') submit: any;
  @Input('components') formData: any;
  @Input('group') selectGroup: any;
  @Input('groupName') groupName: any;
  @Input('message') lang: string;
  @Input('collectionS') collectionS: any;
  @Input('data') initData: any;
  @Input('mod') mod: boolean;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){

    this.formData.filter((e: boolean) => e['visible'] === false).forEach(element => {
      $(`#${element.id}`).parent().siblings().css('display', 'none');
      $(`#${element.id}`).parent().css('display', 'none');
    });
  
  }

}
