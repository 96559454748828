import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { headers } from './data.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class SubmitService {
    constructor(private http: HttpClient) { }

    submit(dt) {
        return this.http.post(environment.BASE_URL_FORM_API + `v2/forms`, dt, {headers});
    }

}
