import {Component, Injectable, Input, OnInit} from '@angular/core';
import { MessageService } from '../../_services/message.service';
import { messages } from '../../_helpers/message.dialog';
import { formatDt } from '../../_services/datepicker-format';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';


@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
@Injectable({ providedIn: 'root' })
export class InputDateComponent implements OnInit {
  @Input('inputDate') eee: any;
  @Input('ctrlsub') submitted: boolean;
  @Input('language') language: string;
  @Input('elem') inputData: any;
  @Input('elemID') ID: string;
  message = messages;

  constructor(
      public messageService: MessageService,
      private dateAdapter: DateAdapter<Date>
  ) { this.dateAdapter.setLocale('it'); }

  ngOnInit() {
  }

  dtChange(event) {
     this.eee.controls[this.ID].setValue(
         (event.target.value !== null) ? formatDt(event.target.value) : '', // 0000-00-00 00:00:00
     {
       emitEvent: false,
       emitModelToViewChange: false,
       emitViewToModelChange: false
     });
  }

  get fval() { return this.eee.controls; }

}

