import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {EmailPasswordResetService} from '../_services/email-password-reset.service';
import {ConfirmPasswordValidator} from '../_services/password-validator.service';
import {scrollToError} from '../_helpers/functions';
import {searchParams} from '../_helpers/functions';
import {messages} from '../_helpers/message.dialog';
import {MessageService} from '../_services/message.service';
import { language } from '../../locale/translation';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private resetPassword: EmailPasswordResetService,
        public messageService: MessageService,
        private route: ActivatedRoute
    ) {
    }

    resetpasswordForm: FormGroup;
    submitted = false;
    loading = false;
    language = language;
    messages = messages;
    paramId = null;
    selectLang = this.messages[this.messageService.getLanguage];

    ngOnInit() {

        this.route.queryParams.subscribe(params => {
            this.paramId = params['id_evento'];
        });

        this.resetpasswordForm = this.formBuilder.group({
            authentication_String: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [
                Validators.required, Validators.minLength(6),
                ConfirmPasswordValidator('authentication_String')]
            ]
        });
    }

    get subFval() {
        return this.resetpasswordForm.controls;
    }

    onPasswordSend() {
        const tokenReset = searchParams(this.router.url)[0];
        this.submitted = true;

        // return for here if form is invalid
        if (this.resetpasswordForm.invalid) {
            scrollToError();
            return;
        }
        this.loading = true;

        // INFO: getRawValue() take also disabled elements
        this.resetPassword.resetPassword(Object.assign(this.resetpasswordForm.getRawValue(), {tokenResetPassword: tokenReset})).subscribe(
            (data) => {
                this.toastr.success(this.selectLang.resetPassword.success);
                this.router.navigate(['/login'], { queryParams: { id_evento: this.paramId } });
            },
            (error) => {
                console.log(error);
                this.router.navigate(['/login'], { queryParams: { id_evento: this.paramId } });
                this.toastr.error(error.error.message, this.selectLang.error.title);
                this.loading = false;
            }
        );
    }
}
