import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { headers } from './data.service';
import { environment } from '../../environments/environment';

import { User } from '../_models/user';
import { Token } from '../_models/token';

@Injectable({ providedIn: 'root' })

export class UserService {
  constructor(private http: HttpClient) { }

  register(user: User) {
      return this.http.post(environment.BASE_URL_FORM_API + `Persone/insert`, user, {headers});
  }

  token(token: Token, lang: string) {
    return this.http.post(environment.BASE_URL_FORM_API + `Users/inviaTokenAttivazione/`+ lang, token, {headers});
  }

}
