import {Component, Injectable, Input, OnInit} from '@angular/core';
import {MessageService} from '../../_services/message.service';
import {messages} from '../../_helpers/message.dialog';
import {bindElem} from '../../_helpers/functions';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss']
})

@Injectable({ providedIn: 'root' })

export class InputRadioComponent implements OnInit {

  @Input('inputRadio') ccc: any;
  @Input('ctrlsub') submitted: boolean;
  @Input('language') language: string;
  @Input('elem') inputData: any;
  @Input('elemID') ID: string;
  @Input('dt') components: any;

  RegExp = '';
  message = messages;


  constructor(
      public messageService: MessageService
  ) { }

  ngOnInit() {
    
    this.inputData.map((el) => {
      // Transform into object
      this.inputData = el;
    });

  }

  onChangeCat(event, cat: any) {
    bindElem(this.inputData.subComponents, this.components, event.target.id, this.ccc.controls);

    // General Radio Function - Set value for radio based on id
    this.ccc.controls[this.ID].setValue(cat);
    this.ccc.controls[this.ID].updateValueAndValidity();
  }

  get fval() { return this.ccc.controls; }
}
