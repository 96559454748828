export const messages = {
    it: {
        connection: {
            title: `Network`,
            error: `Nessuna connessione ad internet`
        },
        login: {
            title: `Login`,
            success: `Login effettuata con successo`,
            backLogin: `Ritorna alla login`,
            help: `Contatta il supporto`
        },
        emailResetPassword: {
            success: `E-mail inviata con successo`
        },
        resetPassword: {
            success: `Password modificata`
        },
        register: {
            success: `Registrazione completata`
        },
        error: {
            title: `Errore`
        },
        warning: {
            title: `Attenzione!`
        },
        service: {
            error: `Attualmente non è possibile utilizzare Firefox per accedere. Utilizzare uno dei seguenti browser: Google Chrome, Microsoft Edge, Safari ,Opera`
        },
        file: {
          upload: `File caricato precedentemente`,
          preview: `Preview attualmente non disponibile`,
          extension: `Estensione del file non consentita`,
          size: `Limite Upload File 10MB`,
          success: `File caricato con successo`,
          clear_btn: `Elimina Allegato`
        },
        common: {
            required: `richiesto`,
            number: `deve essere un numero`,
            notCharacters: `non può contenere caratteri`,
            notNumberCharacter: `non può contenere lettere e/o caratteri speciali`,
            voteNotValid: `non valido`,
            notValid: `non valida`
        },
        notFound: {
            page404: `Errore 404`,
            page: `Spiacenti, la pagina richiesta non è stata trovata`
        },
        notValid: {
            form: `Spiacenti, il form non è stato trovato`,
            expired: `scaduto`,
            availability: `Form disponibile dal giorno`,
            to: `al`,
            ops: `Ops! Qualcosa è andato storto...`,
            notPresent: `ancora non presente`
        },
        home: {
            success: `Form inviata con successo`,
            parameterNotValid: `Parametro non presente o non valido`,
            session: `Sessione Scaduta`
        },
        token: {
            success: `Token inviato con successo!`,
            check: `Controlla la tua mail e verifica la ricezione del token.`
        }
    },
    en: {
        connection: {
            title: `Network`,
            error: `No internet connection`
        },
        login: {
            title: `Login`,
            success: `Login successful`,
            backLogin: `Back to login`,
            help: `Contact technical support`
        },
        emailResetPassword: {
            success: `Email successfully sent`
        },
        resetPassword: {
            success: `Password changed`
        },
        register: {
            success: `Registration Successful`
        },
        error: {
            title: `Error`
        },
        warning: {
            title: `Attention!`
        },
        service: {
            error: `Currently, Firefox cannot be used to log in. Use one of the following browsers: Google Chrome, Microsoft Edge, Safari, Opera`
        },
        file: {
            upload: `Previously uploaded file`,
            preview: `Preview not currently available`,
            extension: `File extension not allowed`,
            size: `Max File Upload 10MB`,
            success: `File uploaded successfully`,
            clear_btn: `Delete Attachment`
        },
        common: {
            required: `required`,
            number: `must be a number`,
            notCharacters: `cannot contain characters`,
            notNumberCharacter: `cannot contain letters and / or special characters`,
            voteNotValid: `not valid`,
            notValid: `not valid`,
        },
        notFound: {
            page404: `Error 404`,
            page: `Sorry, the requested page was not found`
        },
        notValid: {
            form: `Sorry, the requested form was not found`,
            expired: `expired`,
            availability: `Form available from`,
            to: `to`,
            ops: `Ops! Something went wrong...`,
            notPresent: `Not present yet`
        },
        home: {
            success: `Form sent successfully`,
            parameterNotValid: `Invalid parameter or not present`,
            session: `Session Expired`
        },
        token: {
            success: `Token sent successfully!`,
            check: `Check your email and verify receipt of the token.`
        }
    }
};
