import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {getParam} from '../_helpers/functions';
import {Router} from '@angular/router';
import {language} from '../../locale/translation';

@Injectable({ providedIn: 'root' })

export class MessageService {
    
    regExpNumber = (/^[0-9]*$/);

    constructor( 
        private router: Router
    ) {}
        
    /* Language */

    public languageSelector = new BehaviorSubject<any>(
        (getParam(this.router.url).hasOwnProperty(['lang']) &&
         !this.regExpNumber.test(encodeURIComponent(getParam(this.router.url)['lang'])) && 
         language[encodeURIComponent(getParam(this.router.url)['lang'])] 
        ) ? encodeURIComponent(getParam(this.router.url)['lang']) : 'it'
    );

    public paramValue = new BehaviorSubject<any>('');                        // Set empty param as default
    public emitMessage = new BehaviorSubject<any>('');

    public get getLanguage(): any {
        return this.languageSelector.value
    }

    public get getParam(): any {
        return this.paramValue.value;
    }

    /* Generic Message */
    public sendMessage(message: any) {
        this.emitMessage.next(message);
    }

    public getMessage(): Observable<any> {
        return this.emitMessage.asObservable();
    }

}
