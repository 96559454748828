import { map } from 'rxjs/operators';
// Global functions
import {Validators} from '@angular/forms';

export function dataEncode(val: any) {
    return btoa(JSON.stringify(val));
}

export function dataDecode(val: any) {
    return JSON.parse(atob(val));
}

export function trimValues(formControls) {
    Object.keys(formControls.controls).forEach((key) => {
        const isString: any = (
            typeof formControls.controls[key].value !== 'object' &&
            typeof formControls.controls[key].value === 'string' &&
            formControls.controls[key].value !== undefined
        );
        (isString) ? setTrimValue(formControls, key) :
                     Object.keys(formControls.get(key).value).forEach((k) => {
                        (isString) ? setTrimValue(formControls, k) : null;
                     });
    });
}

function setTrimValue(formControls, k) {
    formControls.get(k).setValue(
        formControls.get(k).value.trim(),
        {
            emitEvent: false,
            emitModelToViewChange: false,
            emitViewToModelChange: false
        });
    formControls.controls[k].updateValueAndValidity();
}

function scrollTo(el: Element): void {
    (el) ? el.scrollIntoView({ behavior: 'smooth', block: 'center' }) : null;
}

export function scrollToError(): void {
    const invalidElem = document.querySelector('.form-group .ng-invalid');
    scrollTo(invalidElem);
}

export function scrollToErrorToken(): void {
    const invalidElem = document.querySelector('.form-group .is-invalid');
    scrollTo(invalidElem);
}

export function searchParams(url: any) {
    let match,
        pl     = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s: any) => decodeURIComponent(s.replace(pl, ' ')),
        query  = window.location.search.substring(1);

    let urlParams: any = {};
    while (match = search.exec(query)) {
        urlParams[decode(match[1])] = decode(match[2]);
    }
    let values = Object.keys(urlParams).map(e => urlParams[e]);

    return values;
}

export function getParam(url: any) {
    let match,
        pl     = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s: any) => decodeURIComponent(s.replace(pl, ' ')),
        query  = window.location.search.substring(1);

    let urlParams: any = {};
    while (match = search.exec(query)) {
        urlParams[decode(match[1])] = decode(match[2]);
    }
    let values = urlParams;

    return values;
}

export function hasParams() {
    return /[?&]/.test(window.location.search);
}

export function insertParam(key: any , value: any ) {

    let url = window.location.protocol + '//' + window.location.host + window.location.pathname;

    for (let i = 0; i < key.length; i++) {
        let reg = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        let separator = url.indexOf('?') !== -1 ? '&' : '?';
        if (url.match(reg)) {
            url =  url.replace(reg, '$1' + key[i] + '=' + value[i] + '$2');
        } else {
            url = url + separator + key[i] + '=' + value[i];
        }
    }
    // append url in query string
    window.history.pushState({path: url}, '', url);
}

export function getUserBrowser() {
    if (navigator.userAgent.toLowerCase().indexOf('crios') > -1) {
        return 'Chrome';
    } else {
        if (navigator.userAgent.toLowerCase().indexOf('safari') > -1 &&
            navigator.userAgent.toLowerCase().indexOf('chrome') === -1) {
            return 'Safari';
        } else {
            if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
                return 'Chrome';
            } else {
                if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                    return 'Firefox';
                }
            }
        }
    }
}

export const isEmptyObj = obj => {return obj.constructor === Object && Object.keys(obj).length === 0};

export const checkAllObj = (arr) => {
    return arr.every(function(el :any) {
      return Object.prototype.toString.call(el) === '[object Object]';
    });
  }

export function chunkArray(array, size) {
    if (!array) return [];
    const firstChunk = array.slice(0, size); // create the first chunk of given array
    if (!firstChunk.length) {
        return array; // terminate recursion
    }
    return [firstChunk].concat(this.chunkArray(array.slice(size, array.length), size));
}

export const groupBy = key => array =>
array.reduce((objectsByKeyValue, obj) => {
  const value = obj[key];
  objectsByKeyValue[value - 1] = (objectsByKeyValue[value - 1] || []).concat(obj);
  return objectsByKeyValue;
}, {});

export function formatDateIt(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [(!isNaN(Number(day))) ? day : '',
            (!isNaN(Number(month))) ? month : '',
            (!isNaN(Number(year))) ? year : ''
           ].join('-');
}

export function getTimestamp() {
    return Math.round((new Date()).getTime());
}

/* Bind Element Controls */

export function bindElem(arrayElem: any[], mainComp: any, id: any, controls: any) {
    arrayElem.filter(e => e.id === id).forEach(el => {
        if (Object.hasOwnProperty.bind(el)('check') &&
            Object.hasOwnProperty.bind(el)('elements') &&
            Object.hasOwnProperty.bind(el)('active')) {
              el.elements.map((e: any) => {
                if (el.check === null && el.active === true) {
                    $(`#${e}`).parent().removeClass('fadeOut').addClass('fadeInLeft').show();
                    $(`#${e}`).parent().siblings().removeClass('fadeOut').addClass('fadeInLeft').show();
                } else if (el.check !== null && el.active === true) {
                        // FEATURE - NOT YET IMPLEMENTED!!!
                        console.log('el.check', el.check);
                } else{
                    $(`#${e}`).parent().removeClass('fadeInLeft').addClass('fadeOut').slideUp(200, 'linear');
                    $(`#${e}`).parent().siblings().removeClass('fadeInLeft').addClass('fadeOut').slideUp(200, 'linear');
                }
                validatorsBind(mainComp, el.active, controls, e);
            });
        }
    });
  }

/* Bind Element Controls - Set Validators */

  const validatorsBind = (array, active, controls, e) => array.map(elements => {

    const filterEl = elements.components.filter(ele => ele.id === e);
    (filterEl.length) ? filterEl.map(v => {
        (active) ?
            controls[v.id].setValidators(
                Validators.compose([
                    (v.required) ? Validators.required : null,
                    (v.maxLength) ? Validators.maxLength(v.maxLength) : null, 
                    (v.regExp !== null && v.regExp !== '') ? Validators.pattern(v.regExp) : null
                ])
            )
        : (controls[v.id].setValue('',{
            emitEvent: false,
            emitModelToViewChange: false,
            emitViewToModelChange: false
        }),
           controls[v.id].clearValidators(),
           controls[v.id].updateValueAndValidity(),
          ((v.type.includes('check') || v.type.includes('radio')) ? $(`#${v.id}`).find('input').prop('checked', false) : null));
        controls[v.id].updateValueAndValidity();
    }) : null
});