import { HttpHeaders } from '@angular/common/http';

export const headers = new HttpHeaders(
    {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Authorization',
        Authorization : 'Basic ' + btoa('lgcadminservice:04340Max!')
    }
);

export const headerUpload = new HttpHeaders(
    {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Authorization',
        Authorization : 'Basic ' + btoa('lgcadminservice:04340Max!')
    }
);
