import { MessageService } from './_services/message.service';
import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { SafeHtmlPipe } from './shared/pipe/custom-pipe';
// services
// import { InterceptorService } from './_services/interceptor.service';
// import { UserService } from './_services/user.service';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { RenderFormComponent } from './form_component/render-form.component';
import { RenderHtmlComponent } from './html_component/render-html.component';
import { ListBoxComponent } from './listbox-component/listbox.component';
import { InputTextComponent } from './form_component/input-text/input-text.component';
import { InputRadioComponent } from './form_component/input-radio/input-radio.component';
import { SelectDropDownComponent } from './form_component/select-drop-down/select-drop-down.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { InputFileComponent } from './form_component/input-file/input-file.component';
import { InputDateComponent } from './form_component/input-date/input-date.component';
import { InputCheckboxComponent } from './form_component/input-checkbox/input-checkbox.component';
import { NotValidComponent } from './shared/not-valid/not-valid.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { LogoComponent } from './shared/logo/logo.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EmailPasswordResetComponent } from './email-password-reset/email-password-reset.component';
import { TextareaComponent } from './form_component/textarea/textarea.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatInputModule } from '@angular/material/input';
import { SafeHtmlPipePlaceHolder } from './shared/pipe/custom-pipe-placeholder';
import { TooltipComponent } from './form_component/common/tooltip/tooltip.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    RenderFormComponent,
    RenderHtmlComponent,
    ListBoxComponent,
    InputTextComponent,
    InputRadioComponent,
    SelectDropDownComponent,
    NotFoundComponent,
    InputFileComponent,
    InputDateComponent,
    InputCheckboxComponent,
    TextareaComponent,
    TooltipComponent,
    NotValidComponent,
    SpinnerComponent,
    LogoComponent,
    ResetPasswordComponent,
    EmailPasswordResetComponent,
    SafeHtmlPipe,
    SafeHtmlPipePlaceHolder
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    MatNativeDateModule,
    MatDatepickerModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    RecaptchaModule,
    MatInputModule
  ],
  // providers: [UserService, { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [AppComponent, LogoComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
