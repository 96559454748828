import { Component, Injectable, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-render-html',
  templateUrl: './render-html.component.html',
  styleUrls: ['./render-html.component.scss']
})
@Injectable({ providedIn: 'root' })


export class RenderHtmlComponent implements OnInit {

  @Input('html') htmlData: any;
  @Input('message') lang: string;

  constructor() { }

  ngOnInit() {
  }

}
