import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CallDataForm } from 'src/app/_services/form.service';
import { MessageService } from 'src/app/_services/message.service';
import { environment } from 'src/environments/environment';
import {hasParams,searchParams} from '../../_helpers/functions';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})

export class LogoComponent implements OnInit {
  qParam: any;
  logo: string;
  defaultLogo = "./assets/Luiss-Logo.svg";
  regExpNumber = (/^[0-9]*$/);
  isValidParam: any = (
      hasParams() &&
      ( searchParams(this.router.url)[0] !== ''                   &&
        searchParams(this.router.url)[0] !== undefined            &&
        this.regExpNumber.test(searchParams(this.router.url)[0] )
      )
  );
  constructor(
    private router: Router,
    public app: AppComponent
    ) 
    {
    }

  ngOnInit(): void {}

}

