import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {headers} from './data.service';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})

export class EmailPasswordResetService {

    constructor(private http: HttpClient) {
    }

    emailSend(email: string, lingua: string, evento: any) {
        return this.http.post(environment.BASE_URL_FORM_API + `Users/resetPassword/` + email + `/` + lingua + `/`+ evento,
            { headers }
        );
    }

    resetPassword(user: any) {
        return this.http.post(environment.BASE_URL_FORM_API + `Users/changePassword/`, user,
            { headers }
        );
    }
}
