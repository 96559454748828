import {Component, HostListener, Injectable, Input, OnInit} from '@angular/core';
import { CallDataForm } from '../../_services/form.service';
import {MessageService} from '../../_services/message.service';
import {messages} from '../../_helpers/message.dialog';
import {Validators} from '@angular/forms';

declare let $: any;

@Component({
    selector: 'app-select-drop-down',
    templateUrl: './select-drop-down.component.html',
    styleUrls: ['./select-drop-down.component.scss']
})
@Injectable({ providedIn: 'root' })

export class SelectDropDownComponent implements OnInit {

    message = messages;
    constructor(
        private service: CallDataForm,
        public messageService: MessageService
    ) {}

    @Input('language') language: string;
    @Input('selectDrop') ddd: any;
    @Input('ctrlsub') submitted: boolean;
    @Input('group') title: any;
    @Input('collectionS') collectionElem: any;
    @Input('elem') selectData: any;
    @Input('elemID') ID: string;
    @Input('suffix') suffix: string;
    @Input('data') formData: any;
    @Input('grp') group: any;
    @Input('nameGrp') nameGrp: any;

    optionData: any = [];
    change: boolean;
    firstCall = true;
    isLoading = true;
    targetID: any = '';
    block = null;
    event = '';
    isFirstSelect = [];
    selectedElem = [];

    @HostListener('click', ['$event'])
    onClick(event) {
        if (event.type === 'click') {
             this.change = true;
        }
    }

    ngOnInit() {

        this.selectController(
            this.selectData.dato,
            this.ID,
            this.selectData.pkFilteredByValueOfComponent,
            this.selectData.pkOnChangeItemFillComponent,
            this.selectData.nextEndPoint
        );

    }

    selectController(dato, ID, prevID, nextID, ep): void {
        // FILTER - Get ID and Next ID Elements
        // Get next ID based on passed ID
        const filterEL = this.formData.filter((el) => ID === el.id);
        // Get next Dato from current ID
        const filterNextDato = this.formData.filter((el) => ID === el.pkFilteredByValueOfComponent);
        // Filter name group of selected component
        let filterName = filterEL.map((el) => el.name.split('-')[0]);

        // FILTER - Array group
        // Filter collectionElem array of group based on selected name of select group
        this.collectionElem = this.collectionElem.filter((el) => el[filterName]);

        // GET INIT DATA
        let filterAllFirstSel = filterEL.filter((el) =>
            // Get single select
            (el.type === 'select' && el.nextEndPoint === null) ||
            // Get only first select of each group (of select)
            (el.pkFilteredByValueOfComponent === null && el.pkOnChangeItemFillComponent !== null));
        
        // Get first Select
        this.isFirstSelect = filterEL.filter((el) =>
            el.type === 'select' && el.nextEndPoint !== null && 
            el.pkFilteredByValueOfComponent === null && el.pkOnChangeItemFillComponent !== null
         );
                     
        // Get Single Select - Get Last Select
        let singleSelect = filterEL.filter((el) =>
            (el.type === 'select' && el.nextEndPoint === null && el.pkOnChangeItemFillComponent === null));       
        
        // Call single select and only first select of each group (of select)
        (!this.change && filterAllFirstSel.length) ? this.getInitData(this.selectData.entryPointDataSource) : null;

        // SET SUBMIT FOR SELECT
        this.setSubmitObj(this.ID);

        // CLEAR SELECT *** ONLY GROUP OF SELECT ***
        (!singleSelect.length) ? this.clearElements(ID) : null;


        // CALL SERVICE
        (ep !== null) ? this.callService((ep + dato), nextID, filterName, filterNextDato) : null;

        // APPEND VALUES TO INPUT
        (dato !== 'N/D' && filterNextDato.length && filterNextDato[0].type !== 'select') ?
            this.appendValuesToSubSel(filterNextDato, ID, filterEL[0].pkOnChangeItemFillComponent) : null;

    }

    getInitData(ep): void {
        this.service.fetchData(ep).subscribe(data => {
            this.optionData = data;
            this.optionData.unshift('');
            }, (err) => { console.error(err); },
            () => {
                this.setSubmitObj (this.ID);
                this.isLoading = false;
            }
        );
    }

    // Set object with keys option and value for submit, *** only for select component ***
    setSubmitObj(dato) {
        setTimeout(() => { this.onSelect(dato); });
    }

    onSelect(event) {

        (event.type === 'change') ? this.targetID = event.target.offsetParent.id
            : this.targetID = event;
        let elem = $(`#${this.targetID}`).find('option:selected')[0];
        if (elem !== undefined && elem.value !== '') {
            
            this.ddd.controls[this.targetID].setValue(
                {option: elem.innerText, value: elem.value}, {
                    emitEvent: false,
                    emitModelToViewChange: false,
                    emitViewToModelChange: false
                }
            );
            this.ddd.controls[this.targetID].updateValueAndValidity();
        } else {

            this.ddd.controls[this.targetID].setValue(
                '', {
                    emitEvent: false,
                    emitModelToViewChange: false,
                    emitViewToModelChange: false
                }
            );
            this.ddd.controls[this.targetID].updateValueAndValidity();
        }

    }

    updateValues(findID, arrID) {

        for (let [key, value] of Object.entries(findID)) {
            
            (!isNaN(Number(key))) ?
                (value['id'] !== '') ? arrID.push(value['id']) :
                    arrID.push(value['offsetParent'].id)
                : null;
        }
        // Remove empty elements from array arrID
        let filteredArr = arrID.filter((el) => el !== '');

        filteredArr.map((el) => {
            this.ddd.controls[el].patchValue('', {
                emitEvent: false,
                emitModelToViewChange: false,
                emitViewToModelChange: false
            });

            this.ddd.controls[el].updateValueAndValidity();
        });
    }

    clearElements(identifier) {
        // Get group of select filtered by identifier
        this.group.map((el) => {
            el.filter((e) => (e.id === identifier) ? this.selectedElem = el : null);
        });

        // Find index of clicked element
        let indexElem = this.selectedElem.map((el) => el.id.indexOf(identifier));
        let findInd = indexElem.findIndex(index => index === 0);
        // Get next element (PK) from selected element in the array
        let getElement = this.selectedElem.slice(findInd, this.selectedElem.length - 1);
        let nextElemID = getElement.map((el) => el.pkOnChangeItemFillComponent);

        getElement.map((e) => {
            this.setValidatorsFn(e.id, e);
        })
        nextElemID.map((ele) => {
            // Get element by PK
            let targetDiv = $(`#${ele}`).closest('.form-group');
            // Clear all Involved Component
            let htmlTag = targetDiv.closest('.form-group').children().children().last().children();
            htmlTag.val('').empty();

            // UPDATE VALUES
            this.updateValues(htmlTag, []);
        });
    }

    getSelectedGroup(id) {
        this.group.map((el) => {
            el.filter((e) => (e.id === id) ? this.selectedElem = el : null);
        });
        return this.selectedElem;
    }

    selectOption(targetDiv, nextData) {

        (nextData !== 'N/D' && nextData !== '') ?
            (targetDiv.find(`option[value='${nextData}']`)
                .prop('selected', true), this.isLoading = false) : null;
    }

    appendValuesToSubSel(value, ID, nextID) {

        // Get text from previous select
        let checkExist = setInterval(() => {
            if ($(`#${ID}`).find('option:selected').text() !== '') {
                clearInterval(checkExist);
                let text = $(`#${ID}`).find('option:selected').text();
                // Append value
                $(`#${nextID}`).val(text);
                // Update Value to FormGroup for Single Input -> FIX
                let InputVal = this.ddd.controls[nextID];
                InputVal.setValue(text);
                InputVal.updateValueAndValidity();
                // SET VALUE AND OPTION
                this.setSubmitObj(ID);
                this.isLoading = false;
            }
        }, 10);
    }

    dynENDIS(data, filterName) {
        (!this.selectData.disabled && this.isFirstSelect.length > 0) ?
            this.ctrlENDIS((data.length) ? 'enable' : 'disable', filterName, ['select', 'subselect'], this.collectionElem)
        : null
    }

    // Call Service
    callService(ep, identifier, filterName, filterNextDato) {

        this.service.fetchData(ep).subscribe(data => {
            // DYNAMIC ENABLE & DISABLE SELECT OR INPUT
            this.dynENDIS(data, filterName);

            // Control if next data exist and is first Call
            let nextDataExist: any = ( this.firstCall && this.selectData.nextEndPoint !== null );

            let targetDiv = $(`#${identifier}`).find('select');

            // Empty options
            targetDiv.empty();

            // Append empty option
            targetDiv.first().append('<option></option>');

            data.map((el: any) => {
                // Append Option Values
                targetDiv.append(`<option value="${el.value}">${el.option}</option>`);
            });
            this.isLoading = false;
            // Set selected Option
            setTimeout(() => {
                (nextDataExist) ? this.selectOption(targetDiv, filterNextDato[0].dato) : null;
                // APPEND VALUES TO INPUT
                (filterNextDato[0].type !== 'select'                    &&
                 filterNextDato[0].pkOnChangeItemFillComponent === null &&
                 filterNextDato[0].dato !== 'N/D') ?
                    this.appendValuesToSubSel(filterNextDato, identifier, filterNextDato[0].pkOnChangeItemFillComponent) : null;
            });

            }, (err) => console.error(err),
            () => {
            this.firstCall = false;

            this.setSubmitObj(identifier);
        });

    }
    
    // Enable / Disable Control onChange General Select
    ctrlENDIS(act, filterName, type, collection) {
        collection.forEach((elem, index) => {
            elem[filterName[index]].map((el, ind) => {
                el[type[ind]].map((a, i) => {
                    (act === 'enable') ? (ind === 0) ? this.enable(a) : this.disable(a) : null;
                    (act === 'disable') ? (ind === 0) ? this.disable(a) : this.enable(a) : null;
                });
            });
        });
    }

    disable(id) {
        this.ddd.controls[id].disable();
        this.ddd.controls[id].setValidators(null);
        this.ddd.controls[id].updateValueAndValidity();
    }

    enable(id) {

        const subComponentElem = this.getSelectedGroup(id).filter((el) =>
            el.type !== 'select'                     &&
            el.pkFilteredByValueOfComponent !== null &&
            el.pkOnChangeItemFillComponent === null  &&
            el.regExp !== null
        );

        const selectComponentElem = this.getSelectedGroup(id).filter((el) =>
            el.type === 'select' &&
            el.required
        );

        if (subComponentElem.length) {
            subComponentElem.map((el) => {
                if (el.id === id && el.visible) {
                    this.setValidatorsFn(id, el);
                } else {
                    if (el.id === id && this.change){
                        this.setValidatorsFn(id, el);
                    } else {
                        this.ddd.controls[id].setValidators(null);
                        this.ddd.controls[id].updateValueAndValidity();
                    }
                }
                    
            });
        } else {}

        if (selectComponentElem.length) {
            selectComponentElem.map((el) => {
                (el.required && el.visible) ?
                    this.ddd.controls[id].setValidators([Validators.required, Validators.pattern(null)]) : 
                    this.ddd.controls[id].setValidators(null);
                        this.ddd.controls[id].updateValueAndValidity();
            });
        }

        this.change = false;
        this.ddd.controls[id].enable();
        this.ddd.controls[id].updateValueAndValidity();

    }

    setValidatorsFn(id, el){
        this.ddd.controls[id].setValidators(
            Validators.compose([
                (el.required) ? Validators.required : null,
                (el.maxLength) ? Validators.maxLength(el.maxLength) : null, 
                (el.regExp !== null && el.regExp !== '') ? Validators.pattern(el.regExp) : null
            ])
        )
    }

    get fval() { return this.ddd.controls; }

    trackByFn(index, item) {
        return item.id;
    }

}
